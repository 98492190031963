<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增项目"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="项目名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="项目代码">
          <a-input
            v-decorator="['code', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目代码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="拼音码">
          <a-input
            v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入拼音码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="['item_type',{
              rules: [
                { required: true, message: '请选择类型' },
              ]
            }]"
            :loading="typeOptions.length === 0"
            allow-clear
          >
            <a-select-option
              v-for="type in typeOptions"
              :key="type.slug"
              :value="type.slug"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="计价单位">
          <a-input
            v-decorator="['unit', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入单位，如天、个、台等' },
                { max: 10, message: '最多10个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="计价个数">
          <a-input-number
            style="width: 100%;"
            :min="1"
            :max="10000000"
            :step="1"
            :precision="0"
            v-decorator="['count', {
              rules: [
                { required: true, message: '请输入计价个数' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="价格(元)">
          <a-input-number
            style="width: 100%;"
            :min="0.00"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['price', {
              rules: [
                { required: true, message: '请输入价格' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >支持png/jpg图片格式，建议尺寸宽高比180:180px，最多1张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png,.svg"
            :max-count="1"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="项目介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { createItem, getItemTypeOptions } from '@/api/item'

export default {
  name: 'NewItem',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'item_new' }),
      submitting: false,
      isAddWatermark: false,
      typeOptions: [],
      attachments: [] // 图片
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    handleUploadImage(fileList) {
      this.attachments = fileList
    },
    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    // 获取类型
    getOptions() {
      getItemTypeOptions().then(res => {
        if (res.code === 0) {
          this.typeOptions = res.data
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          createItem(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
