var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增项目"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入项目名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入项目名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"项目代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入项目代码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['code', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入项目代码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"拼音码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pinyin', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入拼音码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['pinyin', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入拼音码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['item_type',{
            rules: [
              { required: true, message: '请选择类型' } ]
          }]),expression:"['item_type',{\n            rules: [\n              { required: true, message: '请选择类型' },\n            ]\n          }]"}],attrs:{"loading":_vm.typeOptions.length === 0,"allow-clear":""}},_vm._l((_vm.typeOptions),function(type){return _c('a-select-option',{key:type.slug,attrs:{"value":type.slug}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"计价单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unit', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入单位，如天、个、台等' },
              { max: 10, message: '最多10个字符' } ]
          }]),expression:"['unit', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入单位，如天、个、台等' },\n              { max: 10, message: '最多10个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"计价个数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {
            rules: [
              { required: true, message: '请输入计价个数' } ]
          }]),expression:"['count', {\n            rules: [\n              { required: true, message: '请输入计价个数' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"价格(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['price', {
            rules: [
              { required: true, message: '请输入价格' } ]
          }]),expression:"['price', {\n            rules: [\n              { required: true, message: '请输入价格' },\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"min":0.00,"max":10000000,"step":0.01,"precision":2}})],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('div',{staticStyle:{"line-height":"20px","margin-top":"10px","margin-bottom":"10px"}},[_vm._v("支持png/jpg图片格式，建议尺寸宽高比180:180px，最多1张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png,.svg","max-count":1},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"项目介绍"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }